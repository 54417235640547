import styled from 'styled-components';
import { DefaultIcon } from 'styles/common';

export const DrawerUserTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DrawerUserType = styled.div<{addUser?: boolean}>`
  display: ${({ addUser }) => addUser ? 'block' : 'flex'};
  align-items: center;
  padding: ${({ addUser }) => addUser ? '16px 0' : '16px 14px'};
`;

export const DrawerUserTypeIcon = styled(DefaultIcon)`
  stroke: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.black};
  margin-right: 16px;
`;

export const DrawerDataFormAdditional = styled.div`
  margin-top: 32px;
`;

export const DrawerDataFormAdditionalRow = styled.div<{gridTemplate?: string}>`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate ? gridTemplate : '1fr'};
  grid-column-gap: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Communication = styled.div<{marginTop? : boolean}>`
  margin-top: ${({ marginTop }) => marginTop ? '32px' : null};
`;

export const CommunicationLabel = styled.div`
  ${({ theme }) => theme.text.normal};
  margin-bottom: 16px;
`;

export const CommunicationItems = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 40px;
  grid-auto-columns: max-content;
`;
