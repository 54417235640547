import { AxiosError } from 'axios';
import { TError } from 'models';
import { translate } from './translate';
import { DEFAULT_ERROR_MESSAGE } from '../config/api';

const ERROR_KEYS_TO_FILTER = [
  '(root)',
  'reasons',
  'worker'
];

type TQuoteAPIError = {
  code: string,
  message: string,
  params: Record<string, never>,
};

export const processAPIError = (error: AxiosError, array?: boolean, hideMessages?: boolean) => {
  let errorData: string | TError[];

  if (array) {
    errorData = setDefaultErrorsArray();
  } else {
    errorData = translate({ key: DEFAULT_ERROR_MESSAGE });
  }

  if (error.response) {
    let data = error.response.data;

    if (typeof data === 'string') {
      data = JSON.parse(data);
    }

    if (data?.errors) {
      const filteredErrors = data.errors.filter((error: { code: string, message: string }) => !ERROR_KEYS_TO_FILTER.includes(error.code));
      if (array) {
        errorData = filteredErrors.map((item: { code: string, message: string }) => {
          const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

          return {
            code: hideMessages ? errorCodeTranslated : item.code,
            message: errorCodeTranslated === item.code ? item.message : hideMessages ? '' : errorCodeTranslated
          };
        });
      } else {
        const errors = filteredErrors.map((item: { code: string, message: string }) => {
          const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

          return hideMessages ? `${errorCodeTranslated}` : `${item.code}: ${errorCodeTranslated === item.code ? item.message : errorCodeTranslated}`;
        });
        errorData = errors.join('/n');
      }
    }
  }

  return errorData;
};

export const processAPIQuoteError = (error: AxiosError, array: boolean = false) => {
  let errorData: string | TError[];

  if (array) {
    errorData = setDefaultErrorsArray();
  } else {
    errorData = translate({ key: DEFAULT_ERROR_MESSAGE });
  }

  if (error.response) {

    let data = error.response.data;

    if (typeof data === 'string') {
      data = JSON.parse(data);
    }

    if (data?.response?.errors) {
      errorData = processErrors(data?.response?.errors, errorData, array);
    } else if (data?.errors) {
      errorData = processErrors(data?.errors, errorData, array);
    }
  }

  return errorData;
};

export const processAPICalculationError = (error: AxiosError, array: boolean = false, hideMessages?: boolean) => {
  let errorData: string | TError[];

  if (array) {
    errorData = setDefaultErrorsArray();
  } else {
    errorData = translate({ key: DEFAULT_ERROR_MESSAGE });
  }

  if (error.response) {
    const data = error.response.data;
    if (data?.response?.errors) {
      errorData = processCalculationErrors(data?.response?.errors, errorData, array, hideMessages);
    } else if (data?.errors) {
      errorData = processCalculationErrors(data?.errors, errorData, array, hideMessages);
    }
  }

  return errorData;
};

export const processVehicleDBErrors = (error: AxiosError, array: boolean = false) => {
  let errorData: string | TError[];

  if (array) {
    errorData = setDefaultErrorsArray();
  } else {
    errorData = translate({ key: DEFAULT_ERROR_MESSAGE });
  }

  if (error.response) {
    const data = error.response.data;
    if (data.result) {
      errorData = processErrors([data.result], errorData, array);
    }
  }

  return errorData;

};

const processErrors = (errors: TQuoteAPIError[], errorData: string | TError[], array: boolean) => {
  if (array) {
    errorData = [];
    errors.forEach((item: TQuoteAPIError) => {
      errorData = [
        ...errorData as TError[],
        {
          code: item.code,
          message: translate({ key: `error.${item.code}`, fallback: item.message })
        }
      ];
      if (item.params?.errors) {
        Object.keys(item.params.errors).filter((error: string) => !ERROR_KEYS_TO_FILTER.includes(error)).forEach(subItem => {
          errorData = [
            ...errorData as TError[],
            {
              code: subItem,
              message: translate({ key: `error.${subItem}`, fallback: item.params.errors[subItem] })
            }
          ];
        });
      } else if (item.params?.error) {
        errorData = [
          ...errorData as TError[],
          {
            code: '',
            message: item.params.error
          }
        ];
      } else if (item.params && Object.keys(item.params)?.length) {
        const errorList = Object.keys(item.params).filter((error: string) => !ERROR_KEYS_TO_FILTER.includes(error)).map(error => ({
          code: error,
          message: translate({ key: `error.${error}`, fallback: item.params[error] })
        }));
        if (item.params.reasons) {
          Object.keys(item.params.reasons).forEach(reason => {
            errorList.push(
              {
                code: reason,
                message: translate({ key: `error.${reason}`, fallback: item.params.reasons[reason] })
              }
            );
          });
        }
        errorData = [
          ...errorData as TError[],
          ...errorList
        ];
      }
    });
  } else {
    const errorsArray: string[] = [];
    errors.forEach((item: TQuoteAPIError) => {
      errorsArray.push(`${item.code ? `${item.code}:` : ''} ${(item.code ? translate({ key: `error.${item.code}`, fallback: item.message }) : item.message) || ''}`);
      if (item.params?.errors) {
        Object.keys(item.params.errors).filter((error: string) => !ERROR_KEYS_TO_FILTER.includes(error)).forEach(subItem => {
          errorsArray.push(`${subItem ? `${subItem}:` : ''} ${subItem ? translate({ key: `error.${subItem}`, fallback: item.params.errors[subItem] }) : item.params.errors[subItem]}`);
        });
      } else if (item.params?.error) {
        errorsArray.push(item.params.error);
      }
    });
    errorData = errorsArray.join(' \n ');
  }
  return errorData;
};

const processCalculationErrors = (errors: TQuoteAPIError[], errorData: string | TError[], array: boolean, hideMessages?: boolean) => {
  if (array) {
    errorData = [];
    errors.forEach((item: TQuoteAPIError) => {

      const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

      errorData = [
        ...errorData as TError[],
        {
          code: errorCodeTranslated,
          message: (hideMessages && errorCodeTranslated === item.code) || !hideMessages ? item.message : ''
        }
      ];
      if (item.params?.errors) {
        Object.keys(item.params.errors).filter((error: string) => !ERROR_KEYS_TO_FILTER.includes(error)).forEach(subItem => {
          const errorCodeTranslated = translate({ key: `error.${subItem}`, fallback: subItem });

          errorData = [
            ...errorData as TError[],
            {
              code: errorCodeTranslated,
              message: (hideMessages && errorCodeTranslated === subItem) || !hideMessages ? item.params.errors[subItem] : ''
            }
          ];
        });
      } else if (item.params?.error) {
        errorData = [
          ...errorData as TError[],
          {
            code: '',
            message: item.params.error
          }
        ];
      } else if (item.params && Object.keys(item.params)?.length) {
        const errorList = Object.keys(item.params).filter((error: string) => !ERROR_KEYS_TO_FILTER.includes(error)).map(error => ({
          code: translate({ key: `error.${error}`, fallback: error }),
          message: (hideMessages && translate({ key: `error.${error}`, fallback: error }) === error) || !hideMessages ? item.params[error] : ''
        }));
        if (item.params.reasons) {
          Object.keys(item.params.reasons).forEach(reason => {
            errorList.push(
              {
                code: translate({ key: `error.${reason}`, fallback: reason }),
                message: (hideMessages && translate({ key: `error.${reason}`, fallback: reason }) === reason) || !hideMessages ? item.params.reasons[reason] : ''
              }
            );
          });
        }
        errorData = [
          ...errorData as TError[],
          ...errorList
        ];
      }
    });
  } else {
    const errorsArray: string[] = [];
    errors.forEach((item: TQuoteAPIError) => {
      errorsArray.push(`${item.code ? `${translate({ key: `error.${item.code}`, fallback: item.code })}:` : ''} ${item.message || ''}`);
      if (item.params?.errors) {
        Object.keys(item.params.errors).filter((error: string) => !ERROR_KEYS_TO_FILTER.includes(error)).forEach(subItem => {
          errorsArray.push(`${subItem ? `${translate({ key: `error.${subItem}`, fallback: subItem })}:` : ''} ${item.params.errors[subItem]}`);
        });
      } else if (item.params?.error) {
        errorsArray.push(item.params.error);
      }
    });
    errorData = errorsArray.join(' \n ');
  }
  return errorData;
};

const setDefaultErrorsArray = () => [{ code: '', message: translate({ key: DEFAULT_ERROR_MESSAGE }) }];
