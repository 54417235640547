import { AxiosError } from 'axios';
import { INotification } from '@insly/qmt-reactjs-ui-lib/dist/components/Notification';
import { processAPIError, processAPIQuoteError, processVehicleDBErrors } from './processAPIError';
import { processCalclyErrorsFromResponse } from './processCalclyError';

type TProcessorType = 'vehicleDB' | 'quote' | 'calcly' | 'cepik';

export const errorNotification = (
  error: AxiosError,
  showNotification: (props: Partial<INotification>) => void,
  autoHide: boolean = false,
  processorType?: TProcessorType,
  autoHideTimeout?: number,
  hideMessages?: boolean,
) => {

  let errorData = {
    message: processError(error, processorType, hideMessages) as string,
    preset: 'error',
    autoHide: autoHide,
    autoHideTimeout: autoHideTimeout
  };

  showNotification(errorData);

};

const processError = (error: AxiosError, processorType?: TProcessorType, hideMessages?: boolean) => {
  switch (processorType) {
    case ('vehicleDB'):
      return processVehicleDBErrors(error);
    case ('quote'):
      return processAPIQuoteError(error);
    case ('calcly'):
      return processCalclyErrorsFromResponse(error);
    case ('cepik'):
      return processCalclyErrorsFromResponse(error, false, 'cepik');
    default:
      return processAPIError(error, false, hideMessages);
  }
};
