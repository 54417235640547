import styled from 'styled-components';

export const Error = styled.dl`
  border: ${({ theme }) => `2px solid ${theme.colors.borderErrorLight}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 15px;
  margin-top: 6px;
  display: flex;
  align-items: end;
`;

export const ErrorCode = styled.dt`
  font-family: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.errorLight};
`;

export const ErrorMessage = styled.dd`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.errorLight};
`;
